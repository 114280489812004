

export default {

    methods: {
        getVendors(params) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getVendorUsers(params, vendorId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/users`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getVendorDetails(id) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${id}`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        addNewVendor(obj) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.firstName !== null) {
                    bodyFormData.append("firstname", obj.firstName);
                }
                if (obj.lastName !== null) {
                    bodyFormData.append("lastname", obj.lastName);
                }
                if (obj.category !== null) {
                    bodyFormData.append("category", obj.category);
                }
                if (obj.mobile !== null) {
                    bodyFormData.append("mobile", obj.mobile);
                }
                if (obj.email !== null) {
                    bodyFormData.append("email", obj.email);
                }
                if (obj.vendor !== null) {
                    bodyFormData.append("vendor", obj.vendor);
                }
                if (obj.shouldInvite !== null) {
                    if (obj.shouldInvite === true) {
                        bodyFormData.append("should_invite", 1);
                    }
                    else {
                        bodyFormData.append("should_invite", 0);
                    }
                }


                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        addNewUserToVendor(obj, id) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.firstName !== null) {
                    bodyFormData.append("firstname", obj.firstName);
                }
                if (obj.lastName !== null) {
                    bodyFormData.append("lastname", obj.lastName);
                }
                if (obj.mobile !== null) {
                    bodyFormData.append("mobile", obj.mobile);
                }
                if (obj.email !== null) {
                    bodyFormData.append("email", obj.email);
                }

                if (obj.shouldInvite !== null) {
                    if (obj.shouldInvite === true) {
                        bodyFormData.append("should_invite", 1);
                    }
                    else {
                        bodyFormData.append("should_invite", 0);
                    }
                }


                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${id}/add-user`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        getVendorUsers(params, vendorId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/users`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        getVendorUser(params, vendorId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getAssessmentsOfVendor(params, vendorId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/assessments`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getNonImportedQuestionnaire(params) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/non-imported`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        getNonImportedQuestionnaireDetails(params, questionnaireId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/non-imported/${questionnaireId}`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        importNonImportedQuestionnaire(questionnaireId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/non-imported/${questionnaireId}/import`,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        getImportedQuestionnaires(params) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        removeImportedQuestionnaire(questionnaireId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "DELETE",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getQuestionnaire(params, vendorId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/questionnaires`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        assignQuestionnaireToVendor(obj, vendorId) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.questionnaireId !== null) {
                    bodyFormData.append("questionnaire_id", obj.questionnaireId);
                }

                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${obj.questionnaireId}`,
                    // data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        createTPRAssessment(obj) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.title !== null) {
                    bodyFormData.append("title", obj.title);
                }
                if (obj.description !== null) {
                    bodyFormData.append("description", obj.description);
                }
                // if (obj.user.id !== null) {
                //     bodyFormData.append("user_id", obj.user.id);
                // }
                if (obj.vendors.selectedVendors.length > 0) {
                    obj.vendors.selectedVendors.map((item, i) => {
                        bodyFormData.append(`vendor_ids[${i}]`, item._id);
                    })
                }
                if (obj.selectedQuestionnaire.length > 0) {
                    obj.selectedQuestionnaire.map((item, i) => {
                        bodyFormData.append(`questionnaire_ids[${i}]`, item._id);
                    })
                }

                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/assessments`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        getAssessmentDetails(assessmentId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${assessmentId}`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getQuestionnaireDetails(vendorId, assessmentId, questionnaireId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/assessments/${assessmentId}/questionnaires/${questionnaireId}`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getQuestionnaireResponses(params, assessmentId, questionnaireId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        getQuestionnaireResponse(params, vendorId, assessmentId, questionnaireId, responseId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses/${responseId}`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        updateTPRResponse(obj, vendorId, assessmentId, questionnaireId, responseId) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.recommendation !== null) {
                    bodyFormData.append("recommendation", obj.recommendation);
                }
                if (obj.response !== null) {
                    bodyFormData.append("response", obj.response);
                }
                if (obj.observation !== null) {
                    bodyFormData.append("observation", obj.observation);
                }
                if (obj["evidenceFiles"] && obj.evidenceFiles !== null) {
                    for (let i = 0; i < obj.evidenceFiles.length; i++) {
                        bodyFormData.append(`evidence_files[${i}]`, obj.evidenceFiles[i]);
                    }
                }
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/assessments/${assessmentId}/questionnaires/${questionnaireId}/responses/${responseId}`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },
    }
}